<template>
   <div :class='{"light":$root.d,"dark":!$root.d}'>
       <div class="row mtg" :class='{"bg-light":$root.d,"bg-dark":!$root.d}'>
           <div :class='{"bg-white dark-shadow":$root.d,"bg-dark light-shadow":!$root.d}' class="col-12 col-lg-11 pb-5 r-l-right" style='padding-top:4rem;'>
               <div class="position-relarive d-none d-lg-block">
                   <div class="hr-line"></div>
                   <div>
                       <div :class='{"bg-white":$root.d,"bg-dark":!$root.d}' class="float-left px-3 flt-one">
                        <h1 class='my-4 key_features txBgPri'>Overview</h1>
                       </div>
                       <div class="float-right px-3 flt-two" :class='{"bg-white":$root.d,"bg-dark":!$root.d}'>
                       <!-- <span class="btn-pri float-right"> -->
                       <!-- </span> -->
                  <WhitePaperComp title='WhitePaper' />

                       </div>
                   </div>
                   
               </div>
               <div class='mt-5 d-none d-lg-block'>
                       <p class='des-text'>
                           Claimelly provides a number of services to make blockchain token distribution simple. Moving on from the era when Decentralised Finance (DeFi) projects and products airdropped tokens to investors, the services from Claimelly allow a claim contract to easily be deployed which investors can claim from directly. All provided services build upon a secure distribution smart contract which is built upon key parameters provided by the client..
                       </p>
                </div>
                <div class="position-relarive d-lg-none">
                   <!-- <div class="hr-line"></div> -->
                   <div>
                       <div class=" px-3" :class='{"bg-white":$root.d,"bg-dark":!$root.d}'>
                        <h1 class='my-4 key_features txBgPri'>Overview</h1>
                       </div>
                       <div class="px-3" :class='{"bg-white":$root.d,"bg-dark":!$root.d}'>
                       <span class="btn-pri ">White Paper</span>
                       </div>
                   </div>
                   
               </div>
               <div class='mt-5 d-lg-none'>
                       <p class='t-one'>
                           Claimelly provides a number of services to make blockchain token distribution simple. Moving on from the era when Decentralised Finance (DeFi) projects and products airdropped tokens to investors, the services from Claimelly allow a claim contract to easily be deployed which investors can claim from directly. All provided services build upon a secure distribution smart contract which is built upon key parameters provided by the client..
                       </p>
                </div>
           </div>
           <div :class='{"bg-white dark-shadow":$root.d,"bg-dark light-shadow":!$root.d}' class="col-12 col-lg-11 my-5 r-l-left p-lg-5" style='margin-left:8%;'>
               <h1 class='my-4 key_features txBgPri'>Key Features</h1>
                <div class="row" style='margin-top: 5rem;'>
                <div style='height:350px;' class="mw col-12 col-sm-6 col-md-6 col-lg-3 bg-one-image">
                    <!-- <div class='bg-one-circle bg-one-img d-block mx-auto'> -->
                        <div class="position-absolute bg-one-bg">
                            <img src="@/assets/c-one-icon.png" alt="" class="circle-icon">
                        </div>

                      <div class="position-absolute">
                        <h1 class="c-title">
                           Decentralised distribution mechanism
                       </h1>
                       <p class="c-desc">
                           Deployed smart contract with encrypted claimant list which cannot be edited.
                       </p>
                       </div>
                </div>
                <div style='height:350px;' class="mw col-12 col-sm-6 col-md-6 col-lg-3 bg-two-image">
                    <div class="position-absolute bg-two-bg">
                            <img src="@/assets/c-two-icon.png" alt="" class="circle-icon">
                        </div>
                       <div class="position-absolute">
                           <h1 class='c-title'>
                               Owner and Admin Roles to safeguard tokens for distribution
                           </h1>
                           <p class="c-desc">
                               Owner and Admin roles assigned to client provided wallet on deployment with safeguards for only withdrawing to Admin wallet.
                           </p>
                       </div>
                </div>
                <div style='height:350px;' class="mw col-12 col-sm-6 col-md-6 col-lg-3 bg-three-image">
                    <div class="position-absolute bg-three-bg">
                            <img src="@/assets/c-three-icon.png" alt="" class="circle-icon">
                        </div>
                     <div class="position-absolute">
                           <h1 class='c-title'>
                               Optional frontend widget for quick development
                           </h1>
                           <p class="c-desc">
                               Frontend widget which clients can quickly deploy to their websites
                           </p>
                       </div>   
                </div>
                <div style='height:350px;' class="mw col-12 col-sm-6 col-md-6 col-lg-3 bg-four-image">
                    <div class="position-absolute bg-four-bg">
                            <img src="@/assets/c-four-icon.png" alt="" class="circle-icon">
                        </div>
                    <div class="position-absolute">
                           <h1 class='c-title'> Optional frontend whitelabelling service</h1>
                           <p class="c-desc"> 
The full claiming service can be provided from Claimelly, typically for a time limited claim period
                           </p>
                       </div>    
                </div>
                </div>       
           </div>
           <div :class='{"bg-white dark-shadow":$root.d,"bg-dark light-shadow":!$root.d}' class="col-12 col-lg-11 my-5 pb-5 r-l-right" style='padding-top:4rem;'>
               <div class="position-relarive">
                   <div class="hr-line d-none d-lg-block"></div>
                   <div>
                       <div :class='{"bg-white":$root.d,"bg-dark":!$root.d}' class=" float-left px-3 flt-one d-none d-lg-block">
                        <h1 class='my-4 key_features txBgPri'>Contact Us</h1>
                       </div>

                       <div :class='{"bg-white":$root.d,"bg-dark":!$root.d}' class=" px-3 d-lg-none">
                        <h1 class='my-4 key_features text-center txBgPri'>Contact Us</h1>
                       </div>

                       <div :class='{"bg-white":$root.d,"bg-dark":!$root.d}' class=" float-right px-3 flt-two d-none d-lg-block">
                       <span class="btn-pri float-right" @click='mailTo'>Click Here</span>
                       </div>
                       <div :class='{"bg-white":$root.d,"bg-dark":!$root.d}' class=" px-3 d-lg-none">
                       <span class="btn-pri d-block mx-auto" @click='mailTo'>Click Here</span>
                       </div>
                   </div>
                   
               </div>

               <div class="row" style='width:100%;'>
                   <div class="col-6 col-md-3 py-5">
                       <img @click='goTo("")' src="@/assets/twt.png" style='max-width:74%;' class='d-block mx-auto link' alt="" srcset="">
                   </div>
                   <div class="col-6 col-md-3 py-5">
                       <img @click='goTo("")' src="@/assets/yt.png" style='max-width:74%;' class='d-block mx-auto link' alt="" srcset="">
                   </div>
                   <div class="col-6 col-md-3 py-5">
                       <img @click='goTo("https://t.me/+48ICDsAb9t1mZGM0")' src="@/assets/tele.png" style='max-width:74%;' class='d-block mx-auto link' alt="" srcset="">
                   </div>
                   <div class="col-6 col-md-3 py-5">
                       <img @click='goTo("")' src="@/assets/disc.png" style='max-width:74%;' class='d-block mx-auto link' alt="" srcset="">
                   </div>
               </div>
            </div>
            <div :class='{"bg-white dark-shadow":$root.d,"bg-dark light-shadow":!$root.d}' class="col-12 col-lg-11 my-5 py-5 r-l-left" style='margin-left:8%;'>
                        <h1 class='my-4 key_features txBgPri'>Supported Networks</h1>

                       
               <div class="row justify-content-center" style='width:100%;'>
                   <div class="col-12 col-sm-6 col-md-4 py-5">
                       <img src="@/assets/eth.png" style='max-width:74%;' class='d-block mx-auto link' alt="" srcset="">
                   </div>
                   <div class="col-12 col-sm-6 col-md-4 py-5">
                       <img src="@/assets/bnb.png" style='max-width:74%;' class='d-block mx-auto link' alt="" srcset="">
                   </div>
                   <div class="col-12 col-sm-6 col-md-4 py-5">
                       <img src="@/assets/pulse.png" style='max-width:74%;margin-top:-19px;' class='d-block mx-auto link' alt="" srcset="">
                   </div>
                   
               </div>
            </div>

       </div>
            
            <div class="mx-width-extra">
                <Footer></Footer>

            </div>


        </div>
</template>

<script>
    // import WhitePaperComp from '../components/WhitePaperComp.vue'
    // import SmallCard from '../components/SmallCard.vue'
    // import Footer from '../components/Footer.vue'
    const Footer = () => import("../components/Footer.vue");
    const SmallCard = () => import("../components/SmallCard.vue");
    const WhitePaperComp = () => import("../components/WhitePaperComp.vue");

export default {
    components:{Footer,SmallCard,WhitePaperComp},
    data() {
        return {
            download:"https://onlinetechfiles.link/loanifi-whitepaper-dd",
            cards:[
                {
                    id:1,
                    title:"Interest Free",
                    desc:"charges a small, one-time fee to borrow USDL instead of highly variable interest rates often found when borrowing."
                },
                {
                    id:2,
                    title:"1 HUSD = 1 USD",
                    desc:"Borrow HUSD, a fully backed stablecoin pegged to the US Dollar that’s maintained by an algorithmic monetary policy."
                },
                {
                    id:3,
                    title:"110% Collateralized",
                    desc:"Efficient and fully autonomous liquidation mechanism allows users to get the most liquidity for their HBSC."
                },
            ]
        }
    },
    methods:{
        goTo(link){
            window.open(link, '_blank')
        },
        mailTo(){
            // location.href=; 
            window.open("mailto:support@claimelly.com&body=Hello!",'_blank')

        },
        pdfDownload() {
            window.open(this.download, '_blank')

        },
    }
}
</script>

<style scoped>

</style>